import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import TitleBar from "../../components/TitleBar/TitleBar";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getLibraryDetails } from "../../redux/actionCreator";
import { reportDetails } from "../../redux/commonReducer";
import i18n from "../../i18n";
import { API_IMAGE_URL } from "../../constants/configuration";
import { t } from "i18next";
function ReportDetails() {
  const { id } = useParams();
  const dispatch = useDispatch();

  // LANGUAGE
  const [lang, setLang] = useState("");
  const langSwitch = i18n?.language === "en";
  useEffect(() => {
    i18n.on("languageChanged", (language) => {
      setLang(language);
    });
  }, [lang, i18n]);

  // SELECTORS
  const reportDetail = useSelector(reportDetails);

  useEffect(() => {
    dispatch(getLibraryDetails(id));
  }, [id]);

  return (
    <div className="report__details">
      <Container>
        <div className="Titlebar_btn">
          <TitleBar
            title={langSwitch ? reportDetail?.title : reportDetail?.title_ar}
            breadcrumbs={[
              { name: t('home'), path: "/" },
              { name: t('library'), path: "/library/reports" },
              { name: t('report'), path: "/library/reports" },
            ]}
            capitalize
            label={t('View PDF')}
            varient={"warning"}
            noBtn
          />
          {reportDetail?.document && (
            <a
              href={`${API_IMAGE_URL}${reportDetail?.file_path}${reportDetail?.document}`}
              target="_blank"
            >
              <button className="btn btn-warning text-center" style={{ minWidth: "140px" }}>
                {t('View PDF')}
              </button>
            </a>
          )}
        </div>

        <img
          src={`${API_IMAGE_URL}${reportDetail?.file_path}${reportDetail?.thumbnail}`}
          alt=""
        />
        <div className='content' dangerouslySetInnerHTML={{__html: langSwitch ? reportDetail?.content : reportDetail?.content_ar}}></div>
      </Container>
    </div>
  );
}

export default ReportDetails;
