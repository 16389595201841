import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import "./Announcement.scss"
import SwiperComponent from '../../commonComponents/SwiperComponent/SwiperComponent'
import "swiper/css";
import { Swiper, SwiperSlide, Au } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import Marquee from "react-fast-marquee";
import { t } from 'i18next';
import i18n from '../../i18n';
import { announcements_Lists, eventLists, featuredEventLists, getHome_announcements } from '../../redux/commonReducer';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getAnnouncements } from '../../redux/actionCreator';

const Announcement = ({ data }) => {
    const dispatch = useDispatch()

    const AnnouncementLists = useSelector(announcements_Lists)

    // LANGUAGE
    const langSwitch = i18n?.language === 'en'
    const [lang, setLang] = useState('')
    useEffect(() => {
        i18n.on('languageChanged', language => {
            setLang(language)

        })
    }, [lang, i18n])


    useEffect(() => {
        const formData = new FormData();
        formData.append("event_type", "Local");
        dispatch(getAnnouncements(formData))
    }, [])




    return (
        <div className='announcement-container'>
            <div className='announcement-text'>
                {t("announcements")}
            </div>
            {
                <Marquee direction={langSwitch ? 'left' : 'right'} pauseOnHover play speed={50} autoFill>
                    <div className='announcement-marquee-container' >
                        {AnnouncementLists?.length && AnnouncementLists?.map((ele, i) => (
                            <>
                                <div className='marquee-dot'></div>
                                <Link to={`/local-events/event-details/${ele?._id}`}>{langSwitch ? ele?.title : ele?.title_ar} </Link>

                            </>
                        ))}
                    </div>
                </Marquee>
            }


        </div >
    )
}

export default Announcement