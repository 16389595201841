import React, { useEffect, useState } from "react";
import TitleBar from "../components/TitleBar/TitleBar";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getBoardmemberDetails, getBoardmembers } from "../redux/actionCreator";
import { board_members, memberDetails } from "../redux/commonReducer";
import { API_IMAGE_URL } from "../constants/configuration";
import i18n from "../i18n";
import { t } from "i18next";

function BoardMembers() {
  const [modalShow, setModalShow] = useState(false);
  const dispatch = useDispatch();

  // SELECTORS
  const teamData = useSelector(board_members);
  const teamDetails = useSelector(memberDetails);

  // LANGUAGE
  const [lang, setLang] = useState("");
  const langSwitch = i18n?.language === "en";
  useEffect(() => {
    i18n.on("languageChanged", (language) => {
      setLang(language);
    });
  }, [lang, i18n]);

  useEffect(() => {
    dispatch(getBoardmembers());
  }, []);

  const handleModalId = (id) => {
    dispatch(getBoardmemberDetails(id));
    setModalShow(true);
  };

  return (
    (
      <section className="board__members">
        <div className="container">
          <TitleBar
            title={t('board members')}
            breadcrumbs={[
              { name: t('home'), path: "/" },
              { name: t('about us'), path: "/about" },
              { name: t('board members'), path: "/board-members" },
            ]}
            noBtn
          />

          <div className="row">
            {teamData?.map((ele, i) => {
              return (
                <div className="col-md-3 col-tab-6" key={i}>
                  <div
                    className="member"
                    onClick={() => handleModalId(ele?._id)}
                  >
                    <div className="avatar">
                      <img
                        src={`${API_IMAGE_URL}${ele?.file_path}${ele?.team_image}`}
                        alt=""
                      />
                    </div>
                    <h3>{langSwitch ? ele?.name : ele?.name_ar}</h3>
                    <h6>{langSwitch ? ele?.designation : ele?.designation_ar}</h6>
                    <p>{langSwitch ? ele?.desc : ele?.desc_ar}</p>
                  </div>
                </div>
              );
            })}
          </div>

          {/* MODAL */}
          <Modal
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={modalShow}
            onHide={() => setModalShow(false)}
          >
            <Modal.Body>
              <div className="member__modal">
                <div
                  className="modal-close"
                  onClick={() => setModalShow(false)}
                >
                  <img src="/assets/img/icons/modal-close.svg" alt="" />
                </div>
                <div className="row align-items-center">
                  <div className="col-md-5">
                    <div className="user">
                      <div className="avatar">
                        <img
                          src={`${API_IMAGE_URL}${teamDetails?.file_path}${teamDetails?.team_image}`}
                          alt=""
                        />
                      </div>
                      <h3>{langSwitch ? teamDetails?.name : teamDetails?.name_ar}</h3>
                      <h6>{langSwitch ? teamDetails?.designation : teamDetails?.designation_ar}</h6>

                      <ul>
                        {teamDetails?.facebook && (
                          <li>
                            <a href={teamDetails?.facebook} target="_blank">
                              <img
                                src="/assets/img/icons/facebook.svg"
                                alt=""
                              />
                            </a>
                          </li>
                        )}
                        {teamDetails?.instagram && (
                          <li>
                            <a href={teamDetails?.instagram} target="_blank">
                              <img
                                src="/assets/img/icons/instagram.svg"
                                alt=""
                              />
                            </a>
                          </li>
                        )}

                        {teamDetails?.twitter && (
                          <li>
                            <a href={teamDetails?.twitter} target="_blank">
                              <img
                                src="/assets/img/icons/twitter.svg"
                                alt=""
                              />
                            </a>
                          </li>
                        )}
                        {teamDetails?.youtube && (
                          <li>
                            <a href={teamDetails?.youtube} target="_blank">
                              <img
                                src="/assets/img/icons/youtube-orange.svg"
                                alt=""
                              />
                            </a>
                          </li>
                        )}
                        {teamDetails?.linkedln && (
                          <li>
                            <a href={teamDetails?.linkedln} target="_blank">
                              <img
                                src="/assets/img/icons/linkedin.svg"
                                alt=""
                              />
                            </a>
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                  <div className="col-md-7 ps-0">
                    <div className="description">
                      <p>{langSwitch ? teamDetails?.desc : teamDetails?.desc_ar}</p>
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </section>
    )
  );
}

export default BoardMembers;
