import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
import TitleBar from "../components/TitleBar/TitleBar";
import { getNewsDetails } from '../redux/actionCreator';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { newsDetail } from '../redux/commonReducer';
import moment from 'moment';
import { API_IMAGE_URL } from '../constants/configuration';
import i18n from '../i18n';
import { t } from 'i18next';

function NewsDetails() {
  const dispatch = useDispatch()
  const { id } = useParams()

  // SELECTORS
  const detailNews = useSelector(newsDetail)

  // LANGUAGE
  const [lang, setLang] = useState("");
  const langSwitch = i18n?.language === "en";
  useEffect(() => {
    i18n.on("languageChanged", (language) => {
      setLang(language);
    });
  }, [lang, i18n]);

  useEffect(() => {
    dispatch(getNewsDetails(id))
  }, [id])

  return (
    <section className='news__details'>
      <Container>
        <TitleBar
          title={langSwitch ? detailNews?.title : detailNews?.title_ar}
          breadcrumbs={[
            { name: t('home'), path: "/" },
            { name: t('news'), path: "/News" },
          ]}
          noBtn
          capitalize
        />

        <div className="details">
          <h6>{moment.utc(detailNews?.created_date).format("LL")}</h6>
          {
            detailNews?.image ?  <img src={`${API_IMAGE_URL}${detailNews?.file_path}${detailNews?.image}`} className='w-100' alt="" /> :
            <div className="details_loader"></div>
          }
          <div className='content' dangerouslySetInnerHTML={{__html: langSwitch ? detailNews?.content : detailNews?.content_ar}}></div>
        </div>
      </Container>
    </section>
  )
}

export default NewsDetails
