import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "../pages/Home/Home";
import About from "../pages/About";
import BoardMembers from "../pages/BoardMembers";
import WhatWeDo from "../pages/WhatWeDo";
import News from "../pages/News";
import NewsDetails from "../pages/NewsDetails";
import Events from "../pages/Events";
import Library from "../pages/Library";
import ReportDetails from "../components/Library/ReportDetails";
import AwarenessDetails from "../components/Library/AwarenessDetails";
import Research from "../pages/Research";
import ResearchDetails from "../pages/ResearchDetails";
import LocalEvents from "../pages/LocalEvents";
import LocalEventDetails from "../pages/LocalEventDetails";
import FAQ from "../pages/FAQ";
import Contact from "../pages/Contact";
import Terms from "../pages/Terms";
import Privacy from "../pages/Privacy";
import InternationalDetails from "../pages/InternationalDetails";
import SearchLists from "../pages/SearchLists";
import PageNotFound from "../pages/PageNotFound";

function RouteHandler() {
  return (
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/board-members" element={<BoardMembers />} />
        <Route path="/what-we-do" element={<WhatWeDo />} />
        <Route path="/News" element={<News />} />
        <Route path="/News/news-details/:id" element={<NewsDetails />} />
        <Route path="/events" element={<Events />} />
        <Route path="/Library/:key" element={<Library />} />
        <Route path="/library/reports/report-details/:id" element={<ReportDetails />} />
        <Route path="/library/awareness/awareness-details/:id" element={<AwarenessDetails />} />
        <Route path="/Research" element={<Research />} />
        <Route path="/Research/research-details/:id" element={<ResearchDetails />} />
        <Route path="/local-events" element={<LocalEvents />} />
        <Route path="/local-events/event-details/:id" element={<LocalEventDetails />} />
        <Route path="/events/event-details/:id" element={<InternationalDetails />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/search" element={<SearchLists />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
  );
}

export default RouteHandler;
