
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { featuredVideosItems, videosItems, videos_count } from "../../redux/commonReducer";
import i18n from "../../i18n";
import { useEffect } from "react";
import { getFeaturedVideos, getVideos } from "../../redux/actionCreator";
import { API_IMAGE_URL } from "../../constants/configuration";
import Fancybox from "../LightBox/fancybox";
import RoundedButton from "../../commonComponents/RoundedButton/RoundedButton";
import DummyImage from "../DummyImage/DummyImage";

function LatestVideos({ classname }) {

  const dispatch = useDispatch();

  // SELECTOR
  const videos_lists = useSelector(videosItems);
  const featured = useSelector(featuredVideosItems);
  const Remaining_count = useSelector(videos_count);

  // LANGUAGE
  const [lang, setLang] = useState("");
  const [allVideos, setAllVideos] = useState([]);
  const [page, setPage] = useState(0);
  const langSwitch = i18n?.language === "en";

  useEffect(() => {
    i18n.on("languageChanged", (language) => {
      setLang(language);
    });
  }, [lang, i18n]);

  useEffect(() => {
    const formData = new FormData();
    formData.append("page", page);
    formData.append("perPage", 8);
    dispatch(getVideos(formData, (res) => {
      setAllVideos([...allVideos, ...res.data])
    }));
    dispatch(getFeaturedVideos());
  }, [page]);
  
  const handleViewMore = () => {
    setPage(page + 1);
  };

  const options = {
    Thumbs: false,
  }
  
  return (
    featured?.length == 0 ?
    <DummyImage size={'60%'} /> :
    <div className={`${classname} latest__videos`}>
      <div className="row">
        {featured?.map((ele, i) => {
          return (
            <Fancybox options={options} >
              <a data-fancybox={`gallery-${i}`} href={ele?.video} key={Math.random()}>
                <div
                  className="featured__video"
                  style={{
                    backgroundImage: `url('${API_IMAGE_URL}${ele?.file_path}${ele?.thumbnail}')`,
                  }}
                  
                >
                  <div className="overlay"></div>
                  <div className="icon">
                    <img src="/assets/img/icons/youtube.svg" alt="" />
                  </div>
                  <h2>{langSwitch ? ele?.title : ele?.title_ar}</h2>
                </div>
              </a>
            </Fancybox>

          );
        })}
      </div>
      <div className="row">

        {allVideos?.map((ele, i) => {
          return (
            <>
              <div className="col-md-3" key={Math.random()}>
                <Fancybox options={options}>
                  <a data-fancybox={`gallery-${i}`} href={ele?.video}>
                    <div
                      className="videos__grid"
                      style={{
                        backgroundImage: `url('${API_IMAGE_URL}${ele?.file_path}${ele?.thumbnail}')`,
                      }}
                    >
                      <div className="overlay"></div>
                      <div className="icon">
                        <img src="/assets/img/icons/youtube.svg" alt="" />
                      </div>
                    </div>
                  </a>
                </Fancybox>
                <h2 className="video-title">
                  {langSwitch ? ele?.title : ele?.title_ar}
                </h2>
              </div>
            </>
          );
        })}

        <div className="col-md-12">
          {!Remaining_count == 0 && (
            <div className="text-center mt-4">
              <RoundedButton
                varient="secondary"
                style={{ minWidth: "190px" }}
                onClick={() => handleViewMore()}
                title="View More"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default LatestVideos;
