import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import TitleBar from "../components/TitleBar/TitleBar";
import "./pagesStyles.scss";
import { whatwedo_list } from "../redux/commonReducer";
import { useDispatch, useSelector } from "react-redux";
import i18n from "../i18n";
import { getWhatWeDo } from "../redux/actionCreator";
import { API_IMAGE_URL } from "../constants/configuration";
import { t } from "i18next";

function WhatWeDo() {
  const dispatch = useDispatch();
  // SELECTOR
  const whatWeDo = useSelector(whatwedo_list);
  // LANGUAGE
  const [lang, setLang] = useState("");
  const langSwitch = i18n?.language === "en";

  useEffect(() => {
    i18n.on("languageChanged", (language) => {
      setLang(language);
    });
  }, [lang, i18n]);

  useEffect(() => {
    dispatch(getWhatWeDo());
  }, []);

  return (
    <section className="whatWeDo">
      <Container>
        <TitleBar
          title={t('what we do')}
          breadcrumbs={[
            { name: t('home'), path: "/" },
            { name: t('what we do'), path: "/what-we-do" },
          ]}
          noBtn
        />
        {whatWeDo?.map((ele, i) => {
          return (
            <div
              className="grid__lists"
              data-aos="fade-up"
              data-aos-duration="1000"
              key={i}
            >
              <div className="row align-items-center">
                <div className="col-md-12">
                  <div className="title">
                    <span>{i <= 10 ? "0" + (i + 1) : i + 1}</span>
                    <h2>{langSwitch ? ele?.title : ele?.title_ar}</h2>
                  </div>
                  <div className="content">
                    <p>{langSwitch ? ele?.content : ele?.content_ar}</p>
                  </div>
                </div>
                {/* <div className="col-md-6">
                  <img
                    src={`${API_IMAGE_URL}${ele?.file_path}${ele?.image}`}
                    className="w-100"
                    alt=""
                  />
                </div> */}
              </div>
            </div>
          );
        })}
      </Container>
    </section>
  );
}

export default WhatWeDo;
