import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
const savedLanguage = localStorage.getItem('localLang');
const localStorageKey = 'localLang';
i18n
  .use(Backend)
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    lng: savedLanguage || 'ar',
    debug: false,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    detection: {
      order: ['localStorage', 'navigator'],
      lookupLocalStorage: localStorageKey,
    },
    backend: {
      loadPath: "/locales/{{lng}}/{{ns}}.json"
    },
  });


export default i18n;
