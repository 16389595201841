import axios from "axios";
import { API_BASE_URL } from "../constants/configuration";

const SERVER_BASE_URL = API_BASE_URL;

const defaultOptions = {
  headers: {
    'Content-Type': 'multipart/form-data',
  },
};

const refresh = () => {
  const refreshToken = localStorage.getItem("refreshToken");
  return refreshToken;
};
const storeToken = (newAccessToken) => {
  localStorage.setItem("token", newAccessToken);
};
// axios instance for making requests
const axiosFormInstance = axios.create(defaultOptions);

// request interceptor for adding token
axiosFormInstance.interceptors.request.use((config) => {
  // add token to request headers
  if (localStorage.getItem("token")) {
    config.headers["Authorization"] = `Bearer ${localStorage.getItem("token")}`;
  } else {
    config.headers["Authorization"] = null;
  }
  return config;
});

axiosFormInstance.interceptors.response.use(
  (response, dispatch) => {
    return response;
  },
  (error) => {
    const originalRequest = error.config;
    if (!error.response) {
      return new Promise((resolve, reject) => {
        // reject(error);
      });
    }
    if (error?.response?.data?.token == false) {
      const refreshToken = refresh();
      originalRequest._retry = true;
      return axios
        .post(`${SERVER_BASE_URL}v1/verifyRefresh`, {
          refreshToken: refreshToken,
        })
        .then(async (res) => {
          const access_token = res.data.accessToken;
          storeToken(access_token);
          const auth = access_token;
          originalRequest.headers.Authorization = `Bearer ${auth}`;

          return axiosFormInstance(originalRequest);
        })
        .catch((err) => {
          localStorage.removeItem("token");
          localStorage.removeItem("refreshToken");
          localStorage.removeItem("user_logged");
          localStorage.removeItem("name");
          localStorage.removeItem("email");
          localStorage.removeItem("phone");
          window.location = "/";
          return Promise.reject();
        });
    } else {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    }
  }
);

export default axiosFormInstance;
