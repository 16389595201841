import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import TitleBar from "../components/TitleBar/TitleBar";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getEvents, getFeaturedEvents } from "../redux/actionCreator";
import { eventLists, eventsLocal_count, featuredEventLists } from "../redux/commonReducer";
import { API_IMAGE_URL } from "../constants/configuration";
import i18n from "../i18n";
import moment from "moment";
import RoundedButton from "../commonComponents/RoundedButton/RoundedButton";
import { t } from "i18next";
import DummyImage from "../components/DummyImage/DummyImage";

function LocalEvents() {
  const dispatch = useDispatch();
  const [eventsItems, seteventsItems] = useState([]);
  const [page, setPage] = useState(0);

  // SELECTOR
  const eventItems = useSelector(eventLists);
  const eventFeaturedItems = useSelector(featuredEventLists);
  const Remaining_count = useSelector(eventsLocal_count);

  // LANGUAGE
  const [lang, setLang] = useState("");
  const langSwitch = i18n?.language === "en";
  useEffect(() => {
    i18n.on("languageChanged", (language) => {
      setLang(language);
    });
  }, [lang, i18n]);

  useEffect(() => {
    const formData = new FormData();
    formData.append("event_type", "Local");
    formData.append("page", page);
    formData.append("perPage", 8);
    // formData.append("home_page", "0");
    dispatch(getEvents(formData, "local", (res) => {
      seteventsItems([...eventsItems, ...res.data]);
    }));

    // FEATURED
    const formData1 = new FormData();
    formData1.append("event_type", "Local");
    dispatch(getFeaturedEvents(formData1));
  }, [page]);

  const handleViewMore = () => {
    setPage(page + 1);
  };

  return (
    <section className="local__events">
      <Container>
        <TitleBar
          title={t('local events')}
          breadcrumbs={[
            { name: t('home'), path: "/" },
            { name: t('local events'), path: "/local-events" },
          ]}
          noBtn
        />
        {
                
                eventFeaturedItems?.length == 0 ?
                  <DummyImage size={'60%'} /> :
                  <>
                  
                  <div className="row">
          {eventFeaturedItems?.map((ele, i) => {
            return (
              <div className="col-md-6" key={i}>
                <Link to={`/local-events/event-details/${ele?._id}`}>
                  <div className="localEvent__grid featured">
                    <img
                      src={`${API_IMAGE_URL}${ele?.file_path}${ele?.image}`}
                      className="main w-100"
                      alt=""
                    />
                    <h2>{langSwitch ? ele?.title : ele?.title_ar}</h2>
                    <div className="localGrid">
                      <div className="icon">
                        <img src="/assets/img/icons/calendar.svg" alt="" />
                        <p>{moment.utc(ele?.event_date).locale(lang).format("LL")}</p>
                      </div>
                      <div className="icon">
                        <img src="/assets/img/icons/location.svg" alt="" />
                        <p>{langSwitch ? ele?.location : ele?.location_ar}</p>
                      </div>
                      <div className="icon">
                        <img src="/assets/img/icons/clock.svg" alt="" />
                        <p>{moment.utc(ele?.event_date).locale(lang).format("LT")}</p>
                      </div>
                    </div>
                    <div className='content' dangerouslySetInnerHTML={{__html: langSwitch ? (ele?.content?.length > 400 ?  ele?.content?.slice(0,400) + '(...)' :ele?.content) : (ele?.content_ar?.length > 400 ? ele?.content_ar?.slice(0,400)+'(...)' : ele?.content_ar)}}></div>
                  </div>
                </Link>
              </div>
            );
          })}
        </div>

        <div className="row">
          {eventsItems?.map((ele, i) => {
            return (
              <div className="col-md-3" key={i}>
                <Link to={`/local-events/event-details/${ele?._id}`}>
                  <div className="localEvent__grid sm__grid">
                    <img
                      src={`${API_IMAGE_URL}${ele?.file_path}${ele?.image}`}
                      className="main w-100"
                      alt=""
                    />
                    <h2>{langSwitch ? ele?.title : ele?.title_ar}</h2>
                    <div className="localGrid">
                      <div className="icon">
                        <img src="/assets/img/icons/calendar.svg" alt="" />
                        <p>{moment.utc(ele?.event_date).locale(lang).format("LL")}</p>
                      </div>
                      <div className="icon">
                        <img src="/assets/img/icons/location.svg" alt="" />
                        <p>{langSwitch ? ele?.location : ele?.location_ar}</p>
                      </div>
                      <div className="icon">
                        <img src="/assets/img/icons/clock.svg" alt="" />
                        <p>{moment.utc(ele?.event_date).locale(lang).format("LT")}</p>
                      </div>
                    </div>
                    <div className='content' dangerouslySetInnerHTML={{__html: langSwitch ? (ele?.content?.length > 100 ?  ele?.content?.slice(0,100) + '(...)' :ele?.content) : (ele?.content_ar?.length > 100 ? ele?.content_ar?.slice(0,100)+'(...)' : ele?.content_ar)}}></div>

                  </div>
                </Link>
              </div>
            );
          })}
        </div>
                  </>
        }
        
        {!Remaining_count == 0 &&  (
          <div className="text-center mb-5 mt-4">
            <RoundedButton
              varient="secondary"
              style={{ minWidth: "190px" }}
              onClick={() => handleViewMore()}
              title="View More"
            />
          </div>
        )}
      </Container>
    </section>
  );
}

export default LocalEvents;
