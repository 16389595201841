import React, { useEffect, useState } from "react";
import logo from "../../assets/images/logo_white.svg";
import Xlogo from "../../assets/images/x.svg";
import fblogo from "../../assets/images/fb.svg";
import insta from "../../assets/images/insta.svg";
import youtubeLogo from "../../assets/images/youtube.svg";
import linkedinLogo from "../../assets/images/linkedin.svg";
import smarthatch from "../../assets/images/smarthatch.svg";
import "./Footer.scss";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getGeneralData } from "../../redux/actionCreator";
import { contact_details, featuredNews, generalData } from "../../redux/commonReducer";
import i18n from "../../i18n";
import { t } from "i18next";
const currentDate = new Date().getFullYear();
const Footer = () => {
  const dispatch = useDispatch()

  // SELECTORS
  const general_data = useSelector(generalData)
  const contactData = useSelector(contact_details)
  const featured_News = useSelector(featuredNews)
  // LANGUAGE
  const [lang, setLang] = useState("");
  const langSwitch = i18n?.language === "en";

  useEffect(() => {
    i18n.on("languageChanged", (language) => {
      setLang(language);
    });
  }, [lang, i18n]);

  useEffect(() => {
    dispatch(getGeneralData())
  }, [])

  useEffect(() => {

  }, [])

  return (
    <>
      <div className="call__action">
        <a href={`tel:${general_data?.floating_contact_number}`}>
          <img src="/assets/img/icons/call-action.svg" alt="" />
        </a>
      </div>

      <footer className="humanRights__footer">
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <div className="footer__widgets footer__lg">
                <div className="logo">
                  <img src={logo} alt="" />
                </div>
                <ul className="social__links">
                  {general_data?.facebook && <li> <a href={`${general_data?.facebook}`} target="_blank"> <img src={fblogo} alt="" /> </a> </li>}
                  {general_data?.instagram && <li> <a href={`${general_data?.instagram}`} target="_blank"> <img src={insta} alt="" /> </a> </li>}
                  {general_data?.twitter && <li> <a href={`${general_data?.twitter}`} target="_blank"> <img src={Xlogo} alt="" /> </a> </li>}
                  {general_data?.youtube && <li> <a href={`${general_data?.youtube}`} target="_blank"> <img src={youtubeLogo} alt="" /> </a> </li>}
                  {general_data?.linkedln && <li> <a href={`${general_data?.linkedln}`} target="_blank"> <img src={linkedinLogo} alt="" /> </a> </li>}
                </ul>
              </div>
            </div>
            <div className="col-md-3">
              <div className="footer__widgets">
                <h4>{t('get in touch')}</h4>
                <p>{langSwitch ? contactData?.address : contactData?.address_ar}</p>
                <p>
                  {t('call us')} : <a href={`tel:${contactData?.telephone}`}><span className="phone">{contactData?.telephone}</span></a> <br /> {t('email')} : <a href={`mailto:${contactData?.email}`}>{contactData?.email}</a>
                </p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="footer__widgets">
                <h4>{t('links')}</h4>
                <div className="footer__navLinks">
                  <ul>
                    <li>
                      <Link to="/">{t('home')}</Link>
                    </li>
                    <li>
                      <Link to="/about">{t('about us')}</Link>
                    </li>
                    <li>
                      <Link to="/what-we-do">{t('what we do')}</Link>
                    </li>
                    {
                      featured_News?.length > 0 &&
                      <li>
                        <Link to="/News">{t('news')}</Link>
                      </li>
                    }

                    <li>
                      <Link to="/Events">{t('international events')}</Link>
                    </li>
                    <li>
                      <Link to="/local-events">{t('local events')}</Link>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <Link to="/Research">{t('research')}</Link>
                    </li>
                    <li>
                      <Link to={`/library/gallery`}>{t('gallery')}</Link>
                    </li>
                    <li>
                      <Link to={`/library/reports`}>{t('report')}</Link>
                    </li>
                    <li>
                      <Link to={`/library/awareness`}>{t('awareness')}</Link>
                    </li>
                    <li>
                      <Link to="/Faq">{t('faq')}</Link>
                    </li>
                    <li>
                      <Link to="/contact">{t('contact us')}</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="privacy">
              <p>
                <Link to='/terms'>{t('terms')}</Link> <span><Link to='/privacy'>{t('privacy')}</Link></span>
              </p>
            </div>
          </div>
        </div>
        <div className="bottom__footer">
          <div className="container mb-0">
            <div className="copyright">
              <p>{t('copyright', { date: currentDate })}</p>
              <p>{t('designed by')} <a href="https://smarthatch.com/" target="_blank"><img src={smarthatch} className="smarthatch" alt="" /></a></p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
