import { createSlice } from '@reduxjs/toolkit';

export const commonSlice = createSlice({
    name : 'commonReducer',
    initialState : {
        value: true,
        board_members :[]
    },
    reducers : {
        updateRedux: (state , data) => {
            state[data.payload.key ] = data.payload.value
        },
    },
})
export const { updateRedux } = commonSlice.actions;

// LOADERS
export const home_loader = (state ) => state.commonReducer.home_loader;
export const search_loader = (state ) => state.commonReducer.search_loader;
export const contact_loader = (state ) => state.commonReducer.contact_loader;

export const value = (state ) => state.commonReducer.value;
export const getHome_banner = (state ) => state.commonReducer.getHome_banner;
export const getHome_announcements = (state ) => state.commonReducer.getHome_announcements;
export const getHome_details = (state ) => state.commonReducer.getHome_details;
export const generalData = (state ) => state.commonReducer.generalData;
export const logo_ar = (state ) => state.commonReducer.logo_ar;
export const logo_en = (state ) => state.commonReducer.logo_en;
export const contact_details = (state ) => state.commonReducer.contact_details;
export const board_members = (state ) => state.commonReducer.board_members;
export const memberDetails = (state ) => state.commonReducer.memberDetails;
export const newsLists = (state ) => state.commonReducer.newsLists;
export const featuredNews = (state ) => state.commonReducer.featuredNews;
export const newsDetail = (state ) => state.commonReducer.newsDetail;
export const newsRemaining_count = (state ) => state.commonReducer.newsRemaining_count;
export const about_details = (state ) => state.commonReducer.about_details;
export const eventLists = (state ) => state.commonReducer.eventLists;
export const eventsLocal_count = (state ) => state.commonReducer.eventsLocal_count;
export const eventsInternational_count = (state ) => state.commonReducer.eventsInternational_count;
export const eventListsInternational = (state ) => state.commonReducer.eventListsInternational;
export const featuredEventLists = (state ) => state.commonReducer.featuredEventLists;
export const eventDetails = (state ) => state.commonReducer.eventDetails;
export const whatwedo_list = (state ) => state.commonReducer.whatwedo_list;
export const galleryItems = (state ) => state.commonReducer.galleryItems;
export const galleryImages = (state ) => state.commonReducer.galleryImages;
export const videosItems = (state ) => state.commonReducer.videosItems;
export const videos_count = (state ) => state.commonReducer.videos_count;
export const featuredVideosItems = (state ) => state.commonReducer.featuredVideosItems;
export const reportItems = (state ) => state.commonReducer.reportItems;
export const report_count = (state ) => state.commonReducer.report_count;
export const awerenessItems = (state ) => state.commonReducer.awerenessItems;
export const awereness_count = (state ) => state.commonReducer.awereness_count;
export const reportDetails = (state ) => state.commonReducer.reportDetails;
export const awarenessDetails = (state ) => state.commonReducer.awarenessDetails;
export const researchesItems = (state ) => state.commonReducer.researchesItems;
export const researches_count = (state ) => state.commonReducer.researches_count;
export const researchDetails = (state ) => state.commonReducer.researchDetails;
export const FAQItems = (state ) => state.commonReducer.FAQItems;
export const termsDetails = (state ) => state.commonReducer.termsDetails;
export const privacyDetails = (state ) => state.commonReducer.privacyDetails;
export const search_teams = (state ) => state.commonReducer.search_teams;
export const search_faq = (state ) => state.commonReducer.search_faq;
export const search_localEvents = (state ) => state.commonReducer.search_localEvents;
export const search_interEvents = (state ) => state.commonReducer.search_interEvents;
export const search_gallery = (state ) => state.commonReducer.search_gallery;
export const gallery_count = (state ) => state.commonReducer.gallery_count;
export const search_videos = (state ) => state.commonReducer.search_videos;
export const search_reports = (state ) => state.commonReducer.search_reports;
export const search_awareness = (state ) => state.commonReducer.search_awareness;
export const search_news = (state ) => state.commonReducer.search_news;
export const search_research = (state ) => state.commonReducer.search_research;
export const search_whatwedo = (state ) => state.commonReducer.search_whatwedo;
export const announcements_Lists = (state ) => state.commonReducer.announcements_Lists;
export const international_Lists = (state ) => state.commonReducer.international_Lists;
export const instaFeed_lists = (state ) => state.commonReducer.instaFeed_lists;


export default commonSlice.reducer;