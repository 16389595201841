import React, { useEffect, useRef, useState } from "react";
import "./SlidesComponent.scss";
import Title from "../Title/Title";
import SwiperComponent from "../SwiperComponent/SwiperComponent";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Button, Row } from "react-bootstrap";
import leftArrowIcon from "../../assets/images/active_left_arrow.svg";
import rightArrowIcon from "../../assets/images/active_left_arrow.svg";
import news1 from "../../assets/images/news1_low.png";
import { API_IMAGE_URL } from "../../constants/configuration";
import moment from 'moment'
import { Link } from "react-router-dom";

let breakPoints = {
  640: {
    slidesPerView: 3,
    spaceBetween: 20,
  },
  768: {
    slidesPerView: 4,
    spaceBetween: 25,
  },
  1024: {
    slidesPerView: 4,
    spaceBetween: 25,
  },
};

let newsData = (index, item) => (
  <SwiperSlide>
    <Link to={`/News/news-details/${item?._id}`}>
      <div className="news-grid" key={index}>
        <Row>
          <div className="section-img">
            <img
              src={`${API_IMAGE_URL}${item?.file_path}${item?.image}`}
              width={"100%"}
            />
          </div>
        </Row>
        <Row className="p-1">
          <h6 className="mt-2">{moment.utc(item?.created_date).format("LL")}</h6>
          <h2>{item?.title}</h2>
          <p>{item?.content}</p>
        </Row>
      </div>
    </Link>
  </SwiperSlide>
);


const SlidesComponent = ({
  title,
  titleurl,
  grid,
  breakpoints = breakPoints,
  spaceBetween = 20,
  dataArray = [],
  tile,
  slidesPerGroup
}) => {
  const [_, setInit] = useState();

  const prevRef = useRef(null);
  const nextRef = useRef(null);

  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <div className="d-flex justify-content-between align-items-center swiper-top mb-3">
        <Title titleurl={titleurl} title={title} />
        <div className="rtl__reverse">
          <span ref={prevRef} className="mr-4 cursor-pointer left__arrow">
            <img src={leftArrowIcon} height={20} />
          </span>
          <span ref={nextRef} className="cursor-pointer right__arrow">
            <img src={rightArrowIcon} height={20} />
          </span>
        </div>
      </div>
      <div style={{ height: "100%" }}>
        <SwiperComponent
          spaceBetween={spaceBetween}
          data={dataArray?.map((item, index) => tile && tile(index, item))}
          prevRef={prevRef}
          nextRef={nextRef}
          setInit={setInit}
          breakpoints={breakpoints}
          grid={grid}
          slidesPerGroup={slidesPerGroup}
        />
      </div>
    </div>
  );
};

export default SlidesComponent;
