import React, { useEffect, useState } from "react";
import { Accordion, Container } from "react-bootstrap";
import TitleBar from "../components/TitleBar/TitleBar";
import { useDispatch, useSelector } from "react-redux";
import { FAQItems } from "../redux/commonReducer";
import i18n from "../i18n";
import { getFAQ } from "../redux/actionCreator";
import { t } from "i18next";

function FAQ() {

  const dispatch = useDispatch();
  // SELECTOR
  const faq_Items = useSelector(FAQItems);

  // LANGUAGE
  const [lang, setLang] = useState("");
  const langSwitch = i18n?.language === "en";

  useEffect(() => {
    i18n.on("languageChanged", (language) => {
      setLang(language);
    });
  }, [lang, i18n]);

  useEffect(() => {
    dispatch(getFAQ())
  }, []);


  return (
    <section className="faq">
      <Container>
        <TitleBar
          title={t('faq')}
          breadcrumbs={[
            { name: t('home'), path: "/" },
            { name: t('faq'), path: "/faq" },
          ]}
          noBtn
        />

        <Accordion defaultActiveKey="0">
          {
            faq_Items?.map((ele, i)=>{
              return(
                <Accordion.Item eventKey={`${i}`}>
                <Accordion.Header>
                {langSwitch ? ele?.question : ele?.question_ar}
                </Accordion.Header>
                <Accordion.Body>
                  <div className="faq__details" >
                    <p>{langSwitch ? ele?.answer : ele?.answer_ar}</p>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              )
            })
          }



          
        </Accordion>
      </Container>
    </section>
  );
}

export default FAQ;
