import React, { useEffect, useState } from "react";
import { Container, Navbar } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import logo from "../../assets/images/logo.svg";
import searchIcon from "../../assets/images/search_icon.svg";
import menuIcon from "../../assets/images/menu_icon.svg";
import menuIconWhite from "../../assets/images/logo_white.svg";
import closeIcon from "../../assets/images/close.svg";
import Xlogo from "../../assets/images/x.svg";
import fblogo from "../../assets/images/fb.svg";
import insta from "../../assets/images/insta.svg";
import youtubeLogo from "../../assets/images/youtube.svg";
import linkedinLogo from "../../assets/images/test11.svg";
import "./Header.scss";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import RoundedButton from "../../commonComponents/RoundedButton/RoundedButton";
import { useTranslation } from "react-i18next";
import { contact_details, featuredNews, generalData, logo_ar, logo_en } from "../../redux/commonReducer";
import { useDispatch, useSelector } from "react-redux";
import { getFeaturedNews } from "../../redux/actionCreator";

export const Header = () => {
  const [scroll, setScroll] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [menuSearch, setSearchOpen] = useState(false);
  const [keyword, setKeyword] = useState('');
  const [lang, setLang] = useState('')
  const { t, i18n } = useTranslation();

  const navigate = useNavigate()
  const location = useLocation()
  // SELECTORS
  const general_data = useSelector(generalData)
  const logo_arabic = useSelector(logo_ar)
  const logo_english = useSelector(logo_en)
  const contactData = useSelector(contact_details)
  const featured_News = useSelector(featuredNews)

  // LANGUAGE
  const langSwitch = i18n?.language === 'en'
  const defaultLang = localStorage.getItem('localLang')



  const changeLanguage = lng => {
    i18n.changeLanguage(lng);
  };


  useEffect(() => {
    if (i18n?.language == 'ar' || defaultLang == 'en-US') {
      document.body.classList.add('rtl');
    } else {
      document.body.classList.remove('rtl');
    }
  }, [langSwitch])



  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 50);
    });
  }, []);

  const handleMenu = (e) => {
    if (e == "search") {
      setSearchOpen(!menuSearch);
      setMenuOpen(false);
    } else if (e == "menu") {
      setMenuOpen(!menuOpen);
      setSearchOpen(false);
    }
  };

  const handleCloseMenu = () => {
    setSearchOpen(false);
    setMenuOpen(false);
  };

  const handleSearch = (e) => {
    navigate('/search', {
      state: {
        key: keyword
      }
    })
    setSearchOpen(false);
    setMenuOpen(false);
  }


  useEffect(() => {
    if (!keyword == '') {
      const keyDownHandler = event => {
        if (event.key === 'Enter') {
          event.preventDefault();
          handleSearch();
        }
      };
      document.addEventListener('keydown', keyDownHandler);
      return () => {
        document.removeEventListener('keydown', keyDownHandler);
      };
    }

  }, [keyword]);




  return (
    <>
      <Container>
        <Navbar className={(scroll ? "main__header scrolled" : "main__header nav-fixed") + (location?.pathname == '/' ? ' bg-white' : '')}>
          <Container>
            <Navbar.Brand>
              <Link to="/">
                {
                  !logo_english == '' && !logo_arabic == '' ? <img
                    alt=""
                    src={langSwitch ? logo_english : logo_arabic}
                    className="d-inline-block align-top logo-image"
                  /> : <img
                    alt=""
                    src={logo}
                    className="d-inline-block align-top logo-image"
                  />
                }

              </Link>
            </Navbar.Brand>
            <Navbar.Text className="d-flex align-center justify-center text-align-center">
              <ul className="language__switch">
                <li className="sm-none contact">
                  <Link to="/contact">{t("contact us")}</Link>
                </li>
                <li className={langSwitch && 'active'} onClick={() => changeLanguage("en")}>EN</li>
                <li className={!langSwitch && 'active'} onClick={() => changeLanguage("ar")}>AR</li>
              </ul>
              <img
                alt=""
                src={searchIcon}
                onClick={() => handleMenu("search")}
                height="18"
                className="mr-3 ml-3 align-self-center pointer icon__header"
              />
              <img
                alt=""
                src={menuIcon}
                height="18"
                className="align-self-center pointer icon__header"
                onClick={() => handleMenu("menu")}
              />
            </Navbar.Text>
          </Container>
        </Navbar>
      </Container>
      {(menuOpen || menuSearch) && (
        <div
          className={menuOpen ? "menu__items open" : "menu__items"}
          data-aos="fade-down"
        >
          <div className="header__logo">
            <div className="logo">
              <img src={menuIconWhite} alt="" />
            </div>
            <div
              className="closeMenu pointer"
              onClick={() => handleCloseMenu()}
            >
              <img src={closeIcon} alt="" />
              <h6>{t("close")}</h6>
            </div>
          </div>
          <div className="row">
            <div className="col-md-8">
              <div
                className="menu__links"
              >
                {menuSearch ? (
                  <div className="search__box">
                    <h2>{t("search here")}</h2>
                    <p>{langSwitch ? general_data?.search_title : general_data?.search_title_ar}</p>
                    <input type="text" placeholder={t('search here')} className="form-control" onChange={(e) => setKeyword(e.target.value)} />
                    <div className="text-right">
                      <RoundedButton noIcon title={t('search')} varient={'primary'} onClick={handleSearch} />
                    </div>
                  </div>
                ) : (
                  <>
                    <ul data-aos="fade-zoom-in" data-aos-duration="1000"
                      onClick={() => handleCloseMenu()}
                    >
                      <li>
                        <span></span>
                        <NavLink to="/">{t("home")}</NavLink>
                      </li>
                      <li>
                        <span></span>
                        <NavLink to="/about">{t("about us")}</NavLink>
                      </li>
                      <li>
                        <span></span>
                        <NavLink to="/what-we-do">{t("what we do")}</NavLink>
                      </li>
                      {
                        featured_News?.length > 0 &&
                        <li>
                          <span></span>
                          <NavLink to={"/News"}>{t("news")}</NavLink>
                        </li>
                      }

                      <li>
                        <span></span>
                        <NavLink to="/local-events">{t("local events")}</NavLink>
                      </li>
                      <li>
                        <span></span>
                        <NavLink to="/Events">{t("international events")}</NavLink>
                      </li>

                    </ul>
                    <ul
                      data-aos="fade-zoom-in"
                      data-aos-duration="2000"
                      onClick={() => handleCloseMenu()}
                    >
                      <li>
                        <span></span>
                        <NavLink to="/Research">{t("research")}</NavLink>
                      </li>
                      <li>
                        <span></span>
                        <NavLink to={`/library/gallery`}>{t("gallery")}</NavLink>
                      </li>
                      <li>
                        <span></span>
                        <NavLink to={`/library/reports`}>{t("report")}</NavLink>
                      </li>
                      <li>
                        <span data-hide-zero={featured_News?.length > 0 ? "true" : "false"}></span>
                        <NavLink to={`/library/awareness`}>{t("awareness")}</NavLink>
                      </li>
                      <li>
                        <span data-hide-zero="true"></span>
                        <NavLink to="/Faq">{t("faq")}</NavLink>
                      </li>
                      <li>
                        <span data-hide-zero="true"></span>
                        <NavLink to="/Contact">{t("contact us")}</NavLink>
                      </li>
                    </ul>
                  </>
                )}
              </div>
            </div>
            <div className="col-md-4">
              <div className="menu__contact">
                <div
                  className="contact__details nav-fade"
                >
                  <h3>{t("address")}</h3>
                  <p>{langSwitch ? contactData?.address : contactData?.address_ar} </p>
                </div>
                <div
                  className="contact__details nav-fade"
                >
                  <h3>{t("email")}</h3>
                  <p><a href={`mailto:${contactData?.email}`}>{contactData?.email}</a></p>
                </div>
                <div
                  className="contact__details nav-fade"
                >
                  <h3>{t("phone")}</h3>
                  <p className="phoneRtl"><a href={`tel:${contactData?.telephone}`}>{contactData?.telephone}</a></p>
                </div>
                <div
                  className="contact__details nav-fade"
                >
                  {
                   ( general_data?.facebook || 
                    general_data?.twitter ||
                    general_data?.youtube ||
                    general_data?.instagram ||
                    general_data?.linkedln) && <h3>{t("follow us")}</h3>
                  }
                  
                  <ul>
                    { general_data?.facebook && <li> <a href={general_data?.facebook} target="_blank"> <img src={fblogo} alt="" /> </a> </li> }
                    { general_data?.twitter && <li> <a href={general_data?.twitter} target="_blank"> <img src={Xlogo} alt="" /> </a> </li> }
                    { general_data?.youtube && <li> <a href={general_data?.youtube} target="_blank"> <img src={youtubeLogo} alt="" /> </a> </li> }
                    { general_data?.instagram && <li> <a href={general_data?.instagram} target="_blank"> <img src={insta} alt="" /> </a> </li> }
                    { general_data?.linkedln && <li> <a href={general_data?.linkedln} target="_blank"> <img src={linkedinLogo} alt="" /> </a> </li> }
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
