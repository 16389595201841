import React from "react";
import "./titleBar.scss";
import RoundedButton from "../../commonComponents/RoundedButton/RoundedButton";
import { NavLink } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

function TitleBar({ title, breadcrumbs, btnUrl, noBtn, label, varient, capitalize, textNone, pt0 }) {


  const navigate = useNavigate();

  function clickHandler(path) {
    path !== null && navigate(path);
  }
  return (
    <div className={pt0 ? "pt-0 titleBar__section" : "titleBar__section"}>
      <div className="titles">
        <ul>
          {breadcrumbs?.map((ele, i) => {
            return <li onClick={() => clickHandler(ele?.path)}>{ele?.name}</li>;
          })}
        </ul>
        <h2 className={(capitalize ? 'text-capitalize ' : 'text-uppercase') + (textNone ? ' text-none' : ' text-uppercase')}>{title}</h2>
      </div>
      {!noBtn && (
        <RoundedButton
          varient={varient}
          title={label}

          onClick={() => navigate(btnUrl)}
          noIcon
        />
      )}
    </div>
  );
}

export default TitleBar;
