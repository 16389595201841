import React from "react";
import TitleBar from "../components/TitleBar/TitleBar";
import { Container, NavLink, Tab, Tabs } from "react-bootstrap";
import { useState } from "react";
import Gallery from "../components/Library/Gallery";
import LatestVideos from "../components/Library/LatestVideos";
import Reports from "../components/Library/Reports";
import Awareness from "../components/Library/Awareness";
import { useLocation, useParams } from "react-router-dom";
import { useEffect } from "react";
import { t } from "i18next";
import i18n from "../i18n";

function Library() {
  let tabKey = useParams();
  const [key, setKey] = useState(tabKey);
  useEffect(()=>{
    setKey(tabKey?.key)
  },[tabKey])

  // LANGUAGE
  const [lang, setLang] = useState("");
  const langSwitch = i18n?.language === "en";

  useEffect(() => {
    i18n.on("languageChanged", (language) => {
      setLang(language);
    });
  }, [lang, i18n]);
  return (
    <section className="library">
      <Container>
        <TitleBar
          title={t('library')}
          breadcrumbs={[
            { name: t('home'), path: "/" },
            { name: t('library'), path: "/Library/gallery" },
          ]}
          noBtn
        />
        <div className="library__grid">
          <Tabs
            id="controlled-tab-example"
            activeKey={`${key}`}
            onSelect={(k) => setKey(k)}
            className="mb-3"
          >
            <Tab eventKey="gallery" title={t('gallery')}>
              <Gallery />
            </Tab>
            <Tab eventKey="latestvideos" title={t('latest videos')}>
             <LatestVideos/>
            </Tab>
            <Tab eventKey="reports" title={t('report')}>
              <Reports/>
            </Tab>
            <Tab eventKey="awareness" title={t('awareness')}>
             <Awareness/>
            </Tab>
          </Tabs>
        </div>
      </Container>
    </section>
  );
}

export default Library;
