import React, { useEffect, useState } from 'react'
import i18n from '../../i18n';

export default function DummyImage({size}) {
    // LANGUAGE
    const [lang, setLang] = useState("");
    const langSwitch = i18n?.language === "en";
    useEffect(() => {
        i18n.on("languageChanged", (language) => {
            setLang(language);
        });
    }, [lang, i18n]);
    return (
        <div className='dummy_image'>
            {
               langSwitch ? <img src={'/assets/img/dummy-en.jpg'} className="dummy-img mt-4" width={size??'100%'} /> :
                    <img src={'/assets/img/dummy-ar.jpg'} className="dummy-img mt-4" width={size??'100%'} />
            }
        </div>

    )
}
