import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import TitleBar from "../components/TitleBar/TitleBar";
import { useDispatch, useSelector } from "react-redux";
import { contactEnquiry } from "../redux/actionCreator";
import { toast } from "react-toastify";
import i18n from "../i18n";
import { contact_details, contact_loader } from "../redux/commonReducer";
import { t } from "i18next";
import { useRef } from "react";

function Contact() {

  const dispatch = useDispatch()


  // SELECTORS
  const contactData = useSelector(contact_details)

  const [disabled, setDisabled] = useState(false)
  const ref = useRef()

  // LANGUAGE
  const [lang, setLang] = useState("");
  const langSwitch = i18n?.language === "en";
  useEffect(() => {
    i18n.on("languageChanged", (language) => {
      setLang(language);
    });
  }, [lang, i18n]);

  const [errors, setErrors] = useState({});
  const [captchaValue, setCaptchaValue] = useState("");
  const [value, setValue] = useState("")
  const [data, setData] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
  });

  useEffect(() => {
    setErrors({})
  }, [langSwitch])

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };

  const validateForm = () => {
    const newErrors = {};
    if (!data?.name?.trim()) {
      newErrors.name = t('name is required');
      setData({ name: '' })
    } else if (!/^[A-Za-z\s]+$/.test(data.name)) {
      newErrors.name = t('Invalid name format, Allow only alphabets');
    }
    if (!data?.email?.trim()) {
      newErrors.email = t('Email is required');
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(data.email)) {
      newErrors.email = t('Invalid email address');
    }
    if (!data?.phone?.trim()) {
      newErrors.phone = t('Phone Number is required');
    } else if (!/^\+?\d{9,13}$/.test(data.phone)) {
      newErrors.phone = t('valid number');
    }
    if (!data?.message?.trim()) {
      newErrors.message = t('Message is required');
    }
    if (!captchaValue == value) {
      newErrors.captcha = t("Invalid captcha");
    }

    setErrors(newErrors);
    return Object.keys(newErrors)?.length === 0;
  };

  function createCaptcha() {
    //clear the contents of captcha div first
    let code;
    document.getElementById("captcha").innerHTML = "";
    let charsArray =
      "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ@!#$%^&*";
    let lengthOtp = 6;
    let captcha = [];
    for (let i = 0; i < lengthOtp; i++) {
      //below code will not allow Repetition of Characters
      var index = Math.floor(Math.random() * charsArray?.length + 1); //get the next character from the array
      if (captcha.indexOf(charsArray[index]) == -1)
        captcha.push(charsArray[index]);
      else i--;
    }
    let canv = document.createElement("canvas");
    canv.id = "captcha";
    canv.width = 100;
    canv.height = 50;
    let ctx = canv.getContext("2d");
    ctx.font = "25px Georgia";
    ctx.strokeText(captcha.join(""), 0, 30);
    //storing captcha so that can validate you can save it somewhere else according to your specific requirements
    code = captcha.join("");
    document.getElementById("captcha").appendChild(canv); // adds the canvas to the body element
    setCaptchaValue(code);
  }

  useEffect(() => {
    createCaptcha();
  }, []);



  const handleSubmit = (e) => {
    e.preventDefault();
    const isValid = validateForm()
    const captchaValid = captchaValue == value
    if (captchaValid) {
      if (isValid) {
        setDisabled(true)
        setValue("");
        dispatch(contactEnquiry(data, (res) => {
          createCaptcha();
          setValue("");
          toast.success(t('thank you contact'), {
            position: "bottom-center",
            autoClose: 3000
          })
          setDisabled(false)
          setData({
            name: '',
            email: '',
            phone: '',
            message: '',
          });
          e.target.reset()
        }))
      }
    } else {
      // toast.error(t("Invalid captcha"), {
      //   position: "bottom-center",
      //   autoClose: 3000,
      // });
    }

  }




  return (
    <section className="contact_us">
      <Container>
        <TitleBar
          title={t('contact us')}
          breadcrumbs={[
            { name: `${t('home')}`, path: "/" },
            { name: `${t('contact us')}`, path: "/contact" },
          ]}
          noBtn
        />

        <div className="contact__details">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="address_grid" data-aos="fade-up" data-aos-duration="1000">
                <div className="icon">
                  <img src="/assets/img/icons/c-location.svg" alt="" />
                </div>
                <div className="content">
                  <h4>{langSwitch ? contactData?.address_title : contactData?.address_title_ar}</h4>
                  <p>{langSwitch ? contactData?.address : contactData?.address_ar}</p>
                </div>
              </div>
              <div className="address_grid" data-aos="fade-up" data-aos-duration="1500">
                <div className="icon">
                  <img src="/assets/img/icons/c-mail.svg" alt="" />
                </div>
                <div className="content">
                  <h4>{t('email')}</h4>
                  <p><a href={`mailto:${contactData?.email}`}>{contactData?.email}</a></p>
                </div>
              </div>
              <div className="address_grid" data-aos="fade-up" data-aos-duration="2000">
                <div className="icon">
                  <img src="/assets/img/icons/c-phone.svg" alt="" />
                </div>
                <div className="content">
                  <h4>{t('phone')}</h4>
                  <p><a href={`tel:${contactData?.mobile}`}>{contactData?.telephone}</a></p>
                </div>
              </div>
            </div>

            <div className="col-md-6 text-right" data-aos="fade-up" data-aos-duration="1500">
              <form onSubmit={(e) => handleSubmit(e)}>
                <div className="forms">
                  <div className="form-group">
                    <input type="text" name="name" ref={ref} className="form-control" placeholder={t('name')} onChange={handleInputChange} />
                    <p className="error">{errors?.name}</p>
                  </div>
                  <div className="form-group">
                    <input type="text" name="email" ref={ref} className="form-control" placeholder={t('email')} onChange={handleInputChange} />
                    <p className="error">{errors?.email}</p>
                  </div>
                  <div className="form-group">
                    <input type="number" name="phone" ref={ref} className="form-control" placeholder={t('phone number')} onChange={handleInputChange} />
                    <p className="error">{errors?.phone}</p>
                  </div>
                  <div className="form-group mb-4">
                    <textarea name="message" ref={ref} placeholder={t('message')} rows="4" className="form-control" onChange={handleInputChange} ></textarea>
                    <p className="error">{errors?.message}</p>
                  </div>
                  <div className="form-group mb-2">

                    <div className="captcha_sec">
                      <div id="captcha"></div>
                      <input
                        type="text"
                        placeholder={t("enter captcha")}
                        id="cpatchaTextBox"
                        className="form-control"
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                      />
                      <div className="text-right captcha_refresh">
                        <div className="refresh_captcha" onClick={() => (createCaptcha(), setValue(""))}><img src="assets/img/icons/refresh.svg" /></div>
                      </div>
                    </div>
                    <p className="error">{errors?.captcha}</p>

                  </div>

                  <button className="btn btn-outline-primary text-uppercase" disabled={disabled}>{t('submit now')}</button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="map__container">
          <div className="row">
            <div
              id="map-container-google-2"
              className="z-depth-1-half map-container"
            >
              <iframe
                src={`${contactData?.google_map_link}`}
                frameborder="0"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
}

export default Contact;
