import React, { useEffect, useState } from "react";
import TitleBar from "../components/TitleBar/TitleBar";
import { Container, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { international_Lists } from "../redux/commonReducer";
import { getInternationalEvents } from "../redux/actionCreator";
import i18n from "../i18n";
import { API_IMAGE_URL } from "../constants/configuration";
import moment from "moment";
import { Link } from "react-router-dom";
import RoundedButton from "../commonComponents/RoundedButton/RoundedButton";
import { t } from "i18next";
import DummyImage from "../components/DummyImage/DummyImage";

function Events() {
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  // SELECTOR
  const eventItems = useSelector(international_Lists);

  // LANGUAGE
  const [lang, setLang] = useState("");
  const langSwitch = i18n?.language === "en";
  useEffect(() => {
    i18n.on("languageChanged", (language) => {
      setLang(language);
    });
  }, [lang, i18n]);


  useEffect(() => {
    dispatch(getInternationalEvents());
  }, []);

  const handleViewMore = () => {
    setPage(page + 1);
  };

  return (
    <section className="events">
      <Container>
        <TitleBar
          title={t('international events')}
          breadcrumbs={[
            { name: t('home'), path: "/" },
            { name: t('international events'), path: "/Events" },
          ]}
          noBtn
        />
        {
          eventItems?.length == 0 ? <DummyImage size={'60%'} /> :
            <div className="row">
              {eventItems?.map((ele, i) => {
                const tooltip = (
                  <Tooltip id="tooltip">
                    <span>{langSwitch ? ele?.title : ele?.title_ar}</span>
                  </Tooltip>
                );
                return (
                  <div className="col-md-3 pe-2 ps-2">
                    <div
                      className="event__grid"
                      style={{
                        backgroundImage: `url('${API_IMAGE_URL}${ele?.file_path}${ele?.image}')`,
                      }}
                    >
                      <div className="overlay"></div>
                      <div className="content">
                        <h2>{moment(ele?.event_date).locale(lang).format("MMMM DD")}</h2>
                        <OverlayTrigger placement="bottom" overlay={tooltip}>
                          <p>{langSwitch ? ele?.title : ele?.title_ar}</p>
                        </OverlayTrigger>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
        }




      </Container>
    </section>
  );
}

export default Events;
