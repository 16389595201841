import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getReports } from "../../redux/actionCreator";
import { reportItems, report_count } from "../../redux/commonReducer";
import RoundedButton from "../../commonComponents/RoundedButton/RoundedButton";
import i18n from "../../i18n";
import { API_IMAGE_URL } from "../../constants/configuration";
import DummyImage from "../DummyImage/DummyImage";

function Reports() {
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [reports, setReports] = useState([]);

  // SELECTOR
  const report_Items = useSelector(reportItems);
  const Remaining_count = useSelector(report_count);
  
  // LANGUAGE
  const [lang, setLang] = useState("");
  const langSwitch = i18n?.language === "en";
  useEffect(() => {
    i18n.on("languageChanged", (language) => {
      setLang(language);
    });
  }, [lang, i18n]);

  useEffect(() => {
    const formData = new FormData();
    formData.append("page", page);
    formData.append("perPage", 8);
    dispatch(
      getReports(formData, (res) => {
        setReports([...reports, ...res.data]);
      })
    );
  }, [page]);

  const handleViewMore = () => {
    setPage(page + 1);
  };

  return (
    reports.length == 0 ?
          <DummyImage size={'60%'} /> :
    <div className="row">
      {reports?.map((ele, i) => {
        return (
          <div className="col-md-3">
            <Link to={`/library/reports/report-details/${ele?._id}`}>
              <div className="library__reports">
                <div className="library__image">
                  <img src={`${API_IMAGE_URL}${ele?.file_path}${ele?.thumbnail}`} alt="" />
                </div>
                <h2>{langSwitch ? ele?.title : ele?.title_ar}</h2>
                <div className='content' dangerouslySetInnerHTML={{__html: langSwitch ? (ele?.content?.length > 100 ?  ele?.content?.slice(0,100) + '(...)' :ele?.content) : (ele?.content_ar?.length > 100 ? ele?.content_ar?.slice(0,100)+'(...)' : ele?.content_ar)}}></div>
              </div>
            </Link>
          </div>
        );
      })}

      <div className="col-md-12">
        {!Remaining_count == 0 && (
          <div className="text-center mb-5 mt-4">
            <RoundedButton
              varient="secondary"
              style={{ minWidth: "190px" }}
              onClick={() => handleViewMore()}
              title="View More"
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default Reports;
