import React, { useEffect, useState } from "react";
import "./WhatWeDoSection.scss";
import whatwedoImage from "../../assets/images/whatwedo_low.png";
import RoundedButton from "../../commonComponents/RoundedButton/RoundedButton";
import { API_IMAGE_URL } from "../../constants/configuration";
import { Link, useNavigate } from "react-router-dom";
import i18n from "../../i18n";
import { t } from "i18next";
import { getWhatWeDo } from "../../redux/actionCreator";
import { useDispatch, useSelector } from "react-redux";
import { whatwedo_list } from "../../redux/commonReducer";
import SlidesComponent from "../../commonComponents/SlidesComponent/SlidesComponent";
import { SwiperSlide } from "swiper/react";
import SwiperComponent from "../../commonComponents/SwiperComponent/SwiperComponent";
import icon from "../../assets/images/right_arrow_yellow.svg";
import Title from "../../commonComponents/Title/Title";
import Swiper from "swiper";
export const WhatWeDoSection = ({ data }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  // SELECTOR
  const whatWeDo = useSelector(whatwedo_list);

  // LANGUAGE
  const [lang, setLang] = useState("");
  const langSwitch = i18n?.language === "en";
  useEffect(() => {
    i18n.on("languageChanged", (language) => {
      setLang(language);
    });
  }, [lang, i18n]);

  useEffect(() => {
    dispatch(getWhatWeDo());
  }, []);

  const whatwedoHome = (
    <>
      {
        whatWeDo?.map((ele, i) => {
          return (
            <SwiperSlide key={i}>
              <div
                className="grid__lists"
                data-aos="fade-up"
                data-aos-duration="1000"
                key={i}
              >
                <div className="row align-items-center">
                  <div className="col-md-12">
                    <div className="whatWeDo__contents">
                      <Title title={t("what we do")} textWhite titleurl='/what-we-do' />
                      <div className="title">
                        <span>{i <= 10 ? "0" + (i + 1) : i + 1}</span>
                        <h2>{langSwitch ? ele?.title : ele?.title_ar}</h2>
                      </div>
                      <div className="content">
                        <p>{langSwitch ? ele?.content : ele?.content_ar}</p>
                        {/* <RoundedButton title={t('know more')} /> */}
                        <Link to='/what-we-do' className="know-more">{t('know more')} <img src={icon} alt="" /></Link>
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-md-6">
                    <img
                      src={`${API_IMAGE_URL}${ele?.file_path}${ele?.image}`}
                      className="w-100 whatwedo"
                      alt=""
                    />
                  </div> */}
                </div>
              </div>

            </SwiperSlide>
          );
        })
      }
    </>
  );


  return (
    <section className="humanRights__whatWeDo">
      <div className="contaoner">
        <SwiperComponent
          pagination
          slidesPerView={1}
          slidesPerGroup={1}
          data={[whatwedoHome]}
          className="home__banner"
          autoplay={{
            delay: 5000,
            disableOnInteraction: true,
            pauseOnMouseEnter: true,
          }}
          breakpoints={{
            1024: {
              slidesPerView: 1,
              spaceBetween: 15,
            },
          }}
        // dataArray={[1, 1, 1, 1, 1, 1, 1, 1]}
        />
      </div>
    </section>
  );
};
