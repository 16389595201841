import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
import TitleBar from '../components/TitleBar/TitleBar'
import { Link } from 'react-router-dom'
import { t } from 'i18next'
import i18n from '../i18n'

function PageNotFound() {
  // LANGUAGE
  const [lang, setLang] = useState("");
  const langSwitch = i18n?.language === "en";

  useEffect(() => {
    i18n.on("languageChanged", (language) => {
      setLang(language);
    });
  }, [lang, i18n]);
    return (
        <section className="page_not_found">
            <Container>
                <div className="error">
                    <img src="/assets/img/404.png" alt="" />
                    <h3>{t('not found')}</h3>
                    <Link to='/'>
                        <div className="back">
                            <img src="/assets/img/icons/back-arrow.svg" alt="" />
                        </div>
                    </Link>
                </div>
            </Container>
        </section>
    )
}

export default PageNotFound
