import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Footer from "./components/Footer/Footer";
import { Header } from "./components/Header/Header";
import Home from "./pages/Home/Home";
import AOS from "aos";
import "aos/dist/aos.css";
import RouteHandler from "./router/routes";
import { BrowserRouter } from "react-router-dom";
import ScrollTop from "./components/ScrollTop";

function App() {
  return (
    <>
      <ScrollTop />
      <Header />
      {/* <BrowserRouter> */}
        <RouteHandler />
      {/* </BrowserRouter> */}
      <Footer />
    </>
  );
}

AOS.init();

export default App;
