import React, { useEffect, useState } from "react";
import SlidesComponent from "../../commonComponents/SlidesComponent/SlidesComponent";
import localEvent1 from "../../assets/images/levent1_high.png";
import calendarIcon from "../../assets/images/calendar.png";
import locationIcon from "../../assets/images/location.png";
import clockIcon from "../../assets/images/clock-circle-svgrepo-com.png";
import { Col, Row } from "react-bootstrap";
import "./LocalEvents.scss";
import { useDispatch, useSelector } from "react-redux";
import { eventLists, featuredEventLists, international_Lists } from "../../redux/commonReducer";
import i18n from "../../i18n";
import { getEvents, getFeaturedEvents } from "../../redux/actionCreator";
import { SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";
import { API_IMAGE_URL } from "../../constants/configuration";
import moment from "moment";
import { t } from "i18next";
import Title from "../../commonComponents/Title/Title";
import DummyImage from "../../components/DummyImage/DummyImage";

const LocalEvents = () => {
  const dispatch = useDispatch();

  // SELECTOR
  const eventItems = useSelector(eventLists);
  const featured = useSelector(featuredEventLists);

  const allLocalEvents = featured?.concat(eventItems)

  const datas = useSelector(international_Lists);
  const InternationaleventItems = datas?.slice(0, 16)

  // LANGUAGE
  const [lang, setLang] = useState("");
  const langSwitch = i18n?.language === "en";
  useEffect(() => {
    i18n.on("languageChanged", (language) => {
      setLang(language);
    });
  }, [lang, i18n]);




  useEffect(() => {
    const formData = new FormData();
    formData.append("event_type", "Local");
    formData.append("perPage", 16);
    // formData.append("home_page", "0");
    dispatch(getEvents(formData, 'local'));
    dispatch(getFeaturedEvents(formData));
  }, []);

  const Tile = (index, ele) => (
    <SwiperSlide>
      <Link to={`/local-events/event-details/${ele?._id}`}>
        <div className="levent-grid" key={index}>
          <Row>
            <div className="event__img">
              <img src={`${API_IMAGE_URL}${ele?.file_path}${ele?.image}`} />
            </div>
          </Row>
          <div className="w-100">
            <h6 className="mt-2 mb-3">{langSwitch ? ele?.title : ele?.title_ar}</h6>
            <div className="details-container">
              <Row>
                <Col sm={6}>
                  <div className="details-first-column">
                    <img src={calendarIcon} className="mr-1 ms-1" />
                    {moment.utc(ele?.event_date).format("LL")}
                  </div>
                  <div className="details-first-column">
                    <img src={clockIcon} className="mr-2 ms-1" width={12} />
                    {moment.utc(ele?.event_date).format('LT')}
                  </div>
                </Col>
                <Col sm={6} className="ps-0">
                  <div className="details-first-column d-flex align-items-start">
                    <img src={locationIcon} className="mr-1 ms-1" />
                    {langSwitch ? ele?.location : ele?.location_ar}
                  </div>
                </Col>
              </Row>
            </div>
            <div>
              <div className='content' dangerouslySetInnerHTML={{ __html: langSwitch ? (ele?.content?.length > 100 ? ele?.content?.slice(0, 100) + '(...)' : ele?.content) : (ele?.content_ar?.length > 100 ? ele?.content_ar?.slice(0, 100) + '(...)' : ele?.content_ar) }}></div>
            </div>
          </div>
        </div>
      </Link>
    </SwiperSlide>
  );

  return (

    <>
      {
        allLocalEvents?.length > 0 ?
          <div className="mb-5 height-100">
            <SlidesComponent
              title={t("local events")}
              titleurl={'/local-events'}
              grid={{
                rows: 2,
                fill: "row",
              }}
              breakpoints={{
                640: {
                  slidesPerView: 2,
                  spaceBetween: 15,
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 15,
                },
                1024: {
                  slidesPerView: 2,
                  spaceBetween: 15,
                },
              }}
              dataArray={allLocalEvents}
              tile={Tile}
            />
          </div> : InternationaleventItems?.length > 0
            ? <div className="dummy__img">
              <Title title={t("Local Events")} />
              <DummyImage />
            </div >
            : null
      }
    </>

  );
};

export default LocalEvents;
