import { t } from 'i18next'
import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
import TitleBar from '../components/TitleBar/TitleBar'
import i18n from '../i18n';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getSearchResults } from '../redux/actionCreator';
import { search_awareness, search_faq, search_gallery, search_interEvents, search_loader, search_localEvents, search_news, search_reports, search_research, search_teams, search_videos, search_whatwedo } from '../redux/commonReducer';
import { API_IMAGE_URL } from '../constants/configuration';
import searchIcon from '../../src/assets/images/search_icon.svg'

function SearchLists() {

    const location = useLocation()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    // SELECTORS 
    const loader = useSelector(search_loader)
    const teamLists = useSelector(search_teams)
    const Faq = useSelector(search_faq)
    const localEvents = useSelector(search_localEvents)
    const intEvents = useSelector(search_interEvents)
    const gallery = useSelector(search_gallery)
    const videoLists = useSelector(search_videos)
    const reportLists = useSelector(search_reports)
    const awarenessLists = useSelector(search_awareness)
    const newsLists = useSelector(search_news)
    const researchLists = useSelector(search_research)
    const whatwedoLists = useSelector(search_whatwedo)

    // LANGUAGE
    const [lang, setLang] = useState("");
    const langSwitch = i18n?.language === "en";

    useEffect(() => {
        i18n.on("languageChanged", (language) => {
            setLang(language);
        });
    }, [lang, i18n]);

    useEffect(() => {
        let data = {
            keyword: location?.state?.key,
        };
        dispatch(getSearchResults(data))
    }, [location])

    useEffect(() => {
        let Searechkeyword = location?.state?.key;
       if(Searechkeyword==undefined){
        navigate('/404')
       }
    }, [location?.state?.key])
    

    const divArray = Array.from({ length: 3 }, (value, index) => index);

    return (

        <section className='search__lists'>
            <Container>
                <TitleBar
                    title={`${t('search results for')}` + ` '${location?.state?.key}'`}
                    textNone
                    breadcrumbs={[
                        { name: `${t('home')}`, path: "/" },
                        { name: `${t('search')}`, path: "/search" },
                    ]}
                    noBtn
                />

                {
                    loader ? <div className="row row-cols-5 sm-d-block">
                        {
                            divArray.map((index) => (
                                <div className="col-md-12" key={index}>
                                    <div className="search_loader"></div>
                                </div>
                            ))
                        }
                    </div> :
                        <>

                            {/* NO DATA */}
                            {
                                teamLists?.length == 0 &&
                                    Faq?.length == 0 &&
                                    localEvents?.length == 0 &&
                                    intEvents?.length == 0 &&
                                    gallery?.length == 0 &&
                                    videoLists?.length == 0 &&
                                    reportLists?.length == 0 &&
                                    awarenessLists?.length == 0 &&
                                    newsLists?.length == 0 &&
                                    researchLists?.length == 0 &&
                                    whatwedoLists?.length == 0 ?
                                    <div className='no-data'>
                                        <img src={searchIcon} alt="" />
                                        <h4>{t('no results found')}</h4>
                                        <p>{t('cannot find')}</p>
                                    </div>
                                    : ""
                            }




                            {/* FAQ */}
                            {
                                Faq?.length ?
                                    <div className="common-results">
                                        <h2 className="search-header">Search Results in FAQ</h2>
                                        {
                                            Faq?.map((ele, i) => {
                                                return (
                                                    <Link to='/faq'>
                                                        <div className="basic__lists" key={i}>
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <div className="content">
                                                                        <h2>{langSwitch ? ele?.question : ele?.question_ar}</h2>
                                                                        <p>{langSwitch ? ele?.answer : ele?.answer_ar}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                )
                                            })
                                        }
                                    </div>

                                    : ''}

                            {/* LOCAL EVENTS */}
                            {
                                localEvents?.length ?
                                    <div className="common-results">
                                        <h2 className="search-header">Search Results in Local Events</h2>
                                        {
                                            localEvents?.map((ele, i) => {
                                                return (
                                                    <Link to={`/local-events/event-details/${ele?._id}`}>
                                                        <div className="basic__lists" key={i}>
                                                            <div className="row align-items-center">
                                                                <div className="col-md-2">
                                                                    <div className="image">
                                                                        <img src={`${API_IMAGE_URL}${ele?.file_path}${ele?.image}`} alt="" />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-9">
                                                                    <div className="content">
                                                                        <h2>{langSwitch ? ele?.title : ele?.title_ar}</h2>
                                                                        <p dangerouslySetInnerHTML={{__html: langSwitch ? (ele?.content?.length >400 ? ele?.content?.slice(0,400) + '(...)' : ele?.content) : (ele?.content_ar?.length > 400 ? ele?.content_ar?.slice(0,400)+'(...)' : ele?.content_ar)}}></p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                )
                                            })
                                        }
                                    </div>

                                    : ''}


                            {/* International EVENTS */}
                            {
                                intEvents?.length ?
                                    <div className="common-results">
                                        <h2 className="search-header">Search Results in International Events</h2>
                                        {
                                            intEvents?.map((ele, i) => {
                                                return (
                                                    <Link to={`/events/event-details/${ele?._id}`}>
                                                        <div className="basic__lists" key={i}>
                                                            <div className="row align-items-center">
                                                                <div className="col-md-2">
                                                                    <div className="image">
                                                                        <img src={`${API_IMAGE_URL}${ele?.file_path}${ele?.image}`} alt="" />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-9">
                                                                    <div className="content">
                                                                        <h2>{langSwitch ? ele?.title : ele?.title_ar}</h2>
                                                                        <p dangerouslySetInnerHTML={{__html: langSwitch ? (ele?.content?.length >400 ? ele?.content?.slice(0,400) + '(...)' : ele?.content) : (ele?.content_ar?.length > 400 ? ele?.content_ar?.slice(0,400)+'(...)' : ele?.content_ar)}}></p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                )
                                            })
                                        }
                                    </div>

                                    : ''}

                            {/* Reports */}
                            {
                                reportLists?.length ?
                                    <div className="common-results">
                                        <h2 className="search-header">Search Results in Reports</h2>
                                        {
                                            reportLists?.map((ele, i) => {
                                                return (
                                                    <Link to={`/library/reports/report-details/${ele?._id}`}>
                                                        <div className="basic__lists" key={i}>
                                                            <div className="row align-items-center">
                                                                <div className="col-md-2">
                                                                    <div className="image">
                                                                        <img src={`${API_IMAGE_URL}${ele?.file_path}${ele?.thumbnail}`} alt="" />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-9">
                                                                    <div className="content">
                                                                        <h2>{langSwitch ? ele?.title : ele?.title_ar}</h2>
                                                                        <p dangerouslySetInnerHTML={{__html: langSwitch ? (ele?.content?.length >400 ? ele?.content?.slice(0,400) + '(...)' : ele?.content) : (ele?.content_ar?.length > 400 ? ele?.content_ar?.slice(0,400)+'(...)' : ele?.content_ar)}}></p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                )
                                            })
                                        }
                                    </div>

                                    : ''}


                            {/* Awareness */}
                            {
                                awarenessLists?.length ?
                                    <div className="common-results">
                                        <h2 className="search-header">Search Results in Awareness</h2>
                                        {
                                            awarenessLists?.map((ele, i) => {
                                                return (
                                                    <Link to={`/library/awareness/awareness-details/${ele?._id}`}>
                                                        <div className="basic__lists" key={i}>
                                                            <div className="row align-items-center">
                                                                <div className="col-md-2">
                                                                    <div className="image">
                                                                        <img src={`${API_IMAGE_URL}${ele?.file_path}${ele?.thumbnail}`} alt="" />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-9">
                                                                    <div className="content">
                                                                        <h2>{langSwitch ? ele?.title : ele?.title_ar}</h2>
                                                                        <p dangerouslySetInnerHTML={{__html: langSwitch ? (ele?.content?.length >400 ? ele?.content?.slice(0,400) + '(...)' : ele?.content) : (ele?.content_ar?.length > 400 ? ele?.content_ar?.slice(0,400)+'(...)' : ele?.content_ar)}}></p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                )
                                            })
                                        }
                                    </div>

                                    : ''}


                            {/* News */}
                            {
                                newsLists?.length ?
                                    <div className="common-results">
                                        <h2 className="search-header">Search Results in News</h2>
                                        {
                                            newsLists?.map((ele, i) => {
                                                return (
                                                    <Link to={`/News/news-details/${ele?._id}`}>
                                                        <div className="basic__lists" key={i}>
                                                            <div className="row align-items-center">
                                                                <div className="col-md-2">
                                                                    <div className="image">
                                                                        <img src={`${API_IMAGE_URL}${ele?.file_path}${ele?.image}`} alt="" />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-9">
                                                                    <div className="content">
                                                                        <h2>{langSwitch ? ele?.title : ele?.title_ar}</h2>
                                                                        <p dangerouslySetInnerHTML={{__html: langSwitch ? (ele?.content?.length >400 ? ele?.content?.slice(0,400) + '(...)' : ele?.content) : (ele?.content_ar?.length > 400 ? ele?.content_ar?.slice(0,400)+'(...)' : ele?.content_ar)}}></p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                )
                                            })
                                        }
                                    </div>

                                    : ''}

                            {/* Research */}
                            {
                                researchLists?.length ?
                                    <div className="common-results">
                                        <h2 className="search-header">Search Results in Research</h2>
                                        {
                                            researchLists?.map((ele, i) => {
                                                return (
                                                    <Link to={`/Research/research-details/${ele?._id}`}>
                                                        <div className="basic__lists" key={i}>
                                                            <div className="row align-items-center">
                                                                <div className="col-md-2">
                                                                    <div className="image">
                                                                        <img src={`${API_IMAGE_URL}${ele?.file_path}${ele?.thumbnail}`} alt="" />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-9">
                                                                    <div className="content">
                                                                        <h2>{langSwitch ? ele?.title : ele?.title_ar}</h2>
                                                                        <p dangerouslySetInnerHTML={{__html: langSwitch ? (ele?.content?.length >400 ? ele?.content?.slice(0,400) + '(...)' : ele?.content) : (ele?.content_ar?.length > 400 ? ele?.content_ar?.slice(0,400)+'(...)' : ele?.content_ar)}}></p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                )
                                            })
                                        }
                                    </div>

                                    : ''}

                            {/* What We Do */}
                            {
                                whatwedoLists?.length ?
                                    <div className="common-results">
                                        <h2 className="search-header">Search Results in What We Do</h2>
                                        {
                                            whatwedoLists?.map((ele, i) => {
                                                return (
                                                    <Link to={`/what-we-do`}>
                                                        <div className="basic__lists" key={i}>
                                                            <div className="row align-items-center">
                                                                {/* <div className="col-md-2">
                                                                    <div className="image">
                                                                        <img src={`${API_IMAGE_URL}${ele?.file_path}${ele?.image}`} alt="" />
                                                                    </div>
                                                                </div> */}
                                                                <div className="col-md-12">
                                                                    <div className="content">
                                                                        <h2>{langSwitch ? ele?.title : ele?.title_ar}</h2>
                                                                        <p dangerouslySetInnerHTML={{__html: langSwitch ? (ele?.content?.length >400 ? ele?.content?.slice(0,400) + '(...)' : ele?.content) : (ele?.content_ar?.length > 400 ? ele?.content_ar?.slice(0,400)+'(...)' : ele?.content_ar)}}></p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                )
                                            })
                                        }
                                    </div>

                                    : ''}

                            {/* TEAM LISTS */}
                            {
                                teamLists?.length ?
                                    <div className="team__lists">
                                        <h2 className="search-header">Search Results in Teams</h2>
                                        <div className="row">
                                            {
                                                teamLists?.map((ele, i) => {
                                                    return (
                                                        <div className="col-md-3">
                                                            <Link to='/board-members'>
                                                                <div
                                                                    className="member"
                                                                >
                                                                    <div className="avatar">
                                                                        <img
                                                                            src={`${API_IMAGE_URL}${ele?.file_path}${ele?.team_image}`}
                                                                            alt=""
                                                                        />
                                                                    </div>
                                                                    <h3>{langSwitch ? ele?.name : ele?.name_ar}</h3>
                                                                    <h6>{langSwitch ? ele?.designation : ele?.designation_ar}</h6>
                                                                    <p>{langSwitch ? ele?.desc : ele?.desc_ar}</p>
                                                                </div>
                                                            </Link>
                                                        </div>
                                                    )
                                                })
                                            }

                                        </div>
                                    </div> : ''
                            }

                        </>
                }




            </Container>
        </section>
    )
}

export default SearchLists
